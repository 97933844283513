import PropTypes from 'prop-types'
import React from 'react'
import logo from "../images/logo.svg"
import "./Logo.css"



const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
   <div className="logo">
   <h1></h1>
     <img src={logo} width="50px" alt="eagle" className="App-logo"></img>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Arya Murthi</h1>
        <p> 
          Fun Fact: I wrote a whole <a href="https://medium.com/@arya.murthi/create-host-and-deploy-a-personal-website-for-1-month-b35591e4248e">Medium article</a> on how I built this website 
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
